import { defineStore } from "pinia";
import type { UppyFile } from "@uppy/core";
import Uppy from "@uppy/core";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import Compressor from "@uppy/compressor";
import Tus from "@uppy/tus";
import { ref } from "vue";
import { toast } from "~/components/ui/toast";

type UploadState = "error" | "waiting" | "preprocessing" | "uploading" | "postprocessing" | "complete";

function emaFilter (
  newValue: number,
  previousSmoothedValue: number,
  halfLife: number,
  dt: number,
): number {
  if ( halfLife === 0 || newValue === previousSmoothedValue ) return newValue;
  if ( dt === 0 ) return previousSmoothedValue;

  return newValue + ( previousSmoothedValue - newValue ) * 2 ** ( -dt / halfLife );
}

export const useUppyStore = defineStore( "uppy", () => {
  const showManager = ref( false );

  const progress = ref( 0 );
  const uppy = ref<Uppy>();

  const files = ref<UppyFile[]>( [] );
  const newFiles = ref<UppyFile[]>( [] );
  const startedFiles = ref<UppyFile[]>( [] );
  const completeFiles = ref<UppyFile[]>( [] );

  const error = ref( false );
  const isUploadStarted = ref( false );
  const isAllComplete = ref( false );
  const isAllErrored = ref( false );
  const isAllPaused = ref( false );
  const isUploadInProgress = ref( false );
  const isSomeGhost = ref( false );

  const lastUpdateTime = ref<ReturnType<typeof performance.now>>();
  const previousUploadedBytes = ref<number>();
  const previousSpeed = ref<number>();
  const previousEta = ref<number>();

  function updateFiles () {
    files.value = uppy.value?.getFiles() ?? [];

    const uppyState = uppy.value?.getState();
    error.value = !!( uppyState?.error ?? false );

    const filesPerState = uppy.value?.getObjectOfFilesPerState();

    newFiles.value = filesPerState?.newFiles ?? [];
    startedFiles.value = filesPerState?.startedFiles ?? [];
    completeFiles.value = filesPerState?.completeFiles ?? [];

    isUploadStarted.value = filesPerState?.isUploadStarted ?? false;
    isAllComplete.value = filesPerState?.isAllComplete ?? false;
    isAllErrored.value = filesPerState?.isAllErrored ?? false;
    isAllPaused.value = filesPerState?.isAllPaused ?? false;
    isUploadInProgress.value = filesPerState?.isUploadInProgress ?? false;
    isSomeGhost.value = filesPerState?.isSomeGhost ?? false;
  }

  function createUppy () {
    const _uppy = new Uppy( {
      restrictions: {
        maxFileSize: 10000000, // 10MB
        allowedFileTypes: ["image/*", "video/*"],
      },
      autoProceed: false,
    } )
      .use( Compressor )
      .use( ThumbnailGenerator )
      .use( Tus, {
        chunkSize: 4000000,
        endpoint: "/api/activities/gallery/upload",
      } );

    _uppy.on( "file-added", ( file ) => {
      const data = file.data; // is a Blob instance
      const url = URL.createObjectURL( data );
      const image = new Image();
      image.src = url;
      image.onload = () => {
        _uppy.setFileMeta( file.id, { width: image.width, height: image.height } );
        URL.revokeObjectURL( url );
      };
    } );

    _uppy.on( "progress", ( percent ) => {
      progress.value = percent;
    } );

    _uppy.on( "upload", () => {
      progress.value = 0;

      const { recoveredState } = _uppy.getState();

      previousSpeed.value = undefined;
      previousEta.value = undefined;

      if ( recoveredState ) {
        previousUploadedBytes.value = Object.values( recoveredState.files ).reduce(
          ( pv, { progress } ) => pv + ( progress.bytesUploaded as number ),
          0,
        );

        _uppy.emit( "restore-confirmed" );
        return;
      }

      lastUpdateTime.value = performance.now();
      previousUploadedBytes.value = 0;
    } );

    _uppy.getState();
    _uppy.on( "complete", () => {
      progress.value = 100;
      files.value.forEach( ( x ) => {
        _uppy.removeFile( x.id, "removed-by-user" );
      } );

      toast( {
        description: "Upload complete!",
      } );
    } );

    _uppy.on( "upload-error", ( file, error ) => {
      toast( {
        variant: "destructive",
        description: "Error uploading file:" + file?.id + ". " + error.message,
      } );
    } );

    _uppy.on( "complete", updateFiles );
    _uppy.on( "upload", updateFiles );
    _uppy.on( "upload-success", updateFiles );
    _uppy.on( "upload-pause", updateFiles );
    _uppy.on( "pause-all", updateFiles );
    _uppy.on( "cancel-all", updateFiles );
    _uppy.on( "file-added", updateFiles );
    _uppy.on( "file-removed", updateFiles );
    _uppy.on( "progress", updateFiles );
    _uppy.on( "thumbnail:generated", updateFiles );
    _uppy.on( "thumbnail:generated", updateFiles );
    _uppy.on( "compressor:complete", updateFiles );
    _uppy.on( "upload-progress", updateFiles );
    _uppy.on( "upload-error", updateFiles );

    return _uppy;
  }

  const totalEta = computed( () => {
    let totalSize = 0;
    let totalUploadedSize = 0;

    startedFiles.value?.forEach( ( file ) => {
      totalSize += file.progress?.bytesTotal || 0;
      totalUploadedSize += file.progress?.bytesUploaded || 0;
    } );

    return computeSmoothEta( {
      uploaded: totalUploadedSize,
      total: totalSize,
      remaining: totalSize - totalUploadedSize,
    } );
  } );

  const state = computed<UploadState>( () => {
    if ( error.value ) {
      return "error";
    }

    if ( isAllComplete.value ) {
      return "complete";
    }

    // if ( false ) {
    //   return "waiting";
    // }

    let state: UploadState = "waiting";

    files.value.forEach ( ( file ) => {
      // If ANY files are being uploaded right now, show the uploading state.
      if ( file.progress?.uploadStarted && !file.progress?.uploadComplete ) {
        return "uploading";
      }

      // If files are being preprocessed AND postprocessed at this time, we show the
      // preprocess state. If any files are being uploaded we show uploading.
      if ( file.progress?.preprocess ) {
        state = "preprocessing";
      }

      // If NO files are being preprocessed or uploaded right now, but some files are
      // being postprocessed, show the postprocess state.
      if ( file.progress?.postprocess && state !== "preprocessing" ) {
        state = "postprocessing";
      }
    } );

    return state;
  } );

  const speedFilterHalfLife = 2000;
  const EtaFilterHalfLife = 2000;

  function computeSmoothEta ( totalBytes: {
    uploaded: number;
    total: number;
    remaining: number;
  } ): number {
    if ( totalBytes.total === 0 || totalBytes.remaining === 0 ) {
      return 0;
    }

    // When state is restored, lastUpdateTime is still nullish at this point.
    lastUpdateTime.value ??= performance.now();
    const dt = performance.now() - lastUpdateTime.value;
    if ( dt === 0 ) {
      return Math.round( ( previousEta.value ?? 0 ) / 100 ) / 10;
    }

    const uploadedBytesSinceLastTick = totalBytes.uploaded - ( previousUploadedBytes.value ?? 0 );
    previousUploadedBytes.value = totalBytes.uploaded;

    // uploadedBytesSinceLastTick can be negative in some cases (packet loss?)
    // in which case, we wait for next tick to update ETA.
    if ( uploadedBytesSinceLastTick <= 0 ) {
      return Math.round( ( previousEta.value ?? 0 ) / 100 ) / 10;
    }
    const currentSpeed = uploadedBytesSinceLastTick / dt;
    const filteredSpeed = previousSpeed.value == null
      ? currentSpeed
      : emaFilter( currentSpeed, previousSpeed.value, speedFilterHalfLife, dt );

    previousSpeed.value = filteredSpeed;
    const instantEta = totalBytes.remaining / filteredSpeed;

    const updatedPreviousEta = Math.max( ( previousEta.value ?? 0 ) - dt, 0 );
    const filteredEta
            = previousEta.value == undefined
              ? instantEta
              : emaFilter( instantEta, updatedPreviousEta, EtaFilterHalfLife, dt );

    previousEta.value = filteredEta;
    lastUpdateTime.value = performance.now();

    return Math.round( filteredEta / 100 ) / 10;
  }

  function upload () {
    showManager.value = true;
    uppy.value?.upload();
  }

  function pauseAll () {
    uppy.value?.pauseAll();
  }

  function cancelAll () {
    uppy.value?.cancelAll();
  }

  function resumeAll () {
    uppy.value?.resumeAll();
  }

  function retryAll () {
    uppy.value?.retryAll();
  }

  function retryUpload ( id: string ) {
    uppy.value?.retryUpload( id );
  }

  function removeFile ( id: string ) {
    uppy.value?.removeFile( id );
  }

  function pauseResume ( id: string ) {
    uppy.value?.pauseResume( id );
  }

  onMounted( () => {
    uppy.value = createUppy();
  } );

  return {
    uppy,
    files,
    newFiles,
    startedFiles,
    completeFiles,
    isAllComplete,
    isAllErrored,
    isAllPaused,
    isSomeGhost,
    isUploadInProgress,
    isUploadStarted,
    progress,
    showManager,
    state,
    totalEta,
    upload,
    pauseAll,
    cancelAll,
    resumeAll,
    retryAll,
    retryUpload,
    removeFile,
    pauseResume,
  };
} );
